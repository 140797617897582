@import "_textArea";
@import "_advanceInputOnly";
@import "_glowButton";

.input .icon-container{
  img{
    max-height: 14px;
  }
}
.input .ant-input-suffix .icon-container{
  img{
    max-height: 14px;
  }
}

.APB_DETAILS{
  .ant-picker{
    width: 100%;
  }
}

.advance-input-container{
  margin-bottom: 20px;

  .ant-form-item {
    margin-bottom: 0;
  }
  .details-page-section-container {
    padding-bottom: unset;
  }

  .ant-select-selector{
    /*border:none !important;
    background:none !important;
    box-shadow: none!important;*/
    font-size: 16px;
    cursor: pointer;
  }

  .ant-input-affix-wrapper-focused{
   /* border:none !important;*/
  }

  .apb-icon{
    color: @green;
  }
  .ant-input-affix-wrapper, .ant-input-affix-wrapper:focus{
    /*border:none !important;*/
    font-size: 16px;
    padding: 6px 12px;
    /*background:none !important;
    box-shadow: none;*/
    input{
      /*background:none !important;*/
      font-size: 16px;
      &:focus{
       /* border:none !important;*/
      }
    }
  }

  .activity-display-value{
    cursor: pointer;
    &:hover{
      -moz-transition: all .5s ease-in;
      -o-transition: all .5s ease-in;
      -webkit-transition: all .5s ease-in;
      transition: all .5s ease-in;
      background-color: @gray-rgba-30;
    }
  }
  .ant-select-selection-item{
    cursor: pointer;
  }

  .advance-input-label{
    font-size: 16px;
    margin: 5px 0 10px 10px;
    display: flex;
   // border-bottom: 1px solid @border-color;

    &.smaller{
      font-size: 14px;
    }
    .advance-label-suffix{
      float: right;
    }

    .add-button-absolute {
      position: absolute;
      top: 0;
      right: 0;
    }

    .activity-card-notes-list {
      height: 161px;
      overflow-y: auto;
      width: 100%;
      padding: 0 15px;
    }
    .activity-card-product-list {
      display: flex;
      flex-wrap: wrap;
      height: 150px;
    }

    .border-when-empty {
      border: 1px dashed @light-grey;
      border-radius: 5px;
    }

    .activity-card-notes-list, .activity-card-product-list {
      margin-top: 15px;
      overflow-y: auto;
      width: 100%;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: @light-grey-rgb;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: @primary-color;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: @green-rgba-70;
    }
  }

  }
  .advance-input-header{
    color: @light-grey;
    font-weight: 500;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.phone-number-input {
  min-height: 32px;
  padding: 4.8px 11px;
  color: @text-color;
  width: 100%;
  border-radius: 5px;
  border: 1px solid @border-color-base;
  transition: all 0.5s;
&:focus {
  border-color: #d6db4b;
  box-shadow: 0 0 0 2px rgba(255, 222, 5, 0.06);
  border-inline-end-width: 1px;
  outline: 0;
}
}
.phone-number-input-container {
position: relative;
margin: 5px;
.phone-number-input-after-addon {
 position: absolute;
 top: 0;
 right: 0;
 padding: 0 11px;
 color: @text-color;
 font-weight: normal;
 font-size: 13px;
 text-align: center;
 background-color: rgba(0, 0, 0, 0.02);
 border: 1px solid #d9d9d9;
 border-radius: 0 5px 5px 0;
 transition: all 0.3s;
 line-height: 1;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
}
}